import React from "react";
import { Link } from "react-router-dom";
const Membership = () => {
  return (
    <>
      <div className="forgot">
        <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="auth-logo">
                <Link to="/">
                  <img src="./images/logo.png"></img>
                </Link>
                <h4>Membership Registration</h4>
                <p>For nurses, physicians, and personal trainers.</p>
              </div>
              <div className="Custom-Form">
                <form>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <input
                        type="email"
                        className="form-control mb-3"
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <input
                        type="number"
                        className="form-control mb-3"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <select className="form-control mb-3">
                        <option>--- Select Country ---</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Ref Number"
                      />
                    </div>
                  </div>
                  <div
                    class="head d-flex align-items-center justify-content-between p-2"
                    style={{ background: "#e7e7e7" }}
                  >
                    <div>
                      <span>
                        Credit Card Payment <b>Secured by Stripe</b>
                      </span>
                    </div>
                    <div>
                      <span>
                        <img
                          src="./images/visa.jpg"
                          class="me-2"
                          style={{ maxWidth: "40px" }}
                        />
                      </span>
                      <span>
                        <img
                          src="./images/master.png"
                          style={{ maxWidth: "40px" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label class="form-label">Card Number</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="1234 1234 1234 1234"
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <label class="form-label">Expiration Date</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Expiration Date"
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <label class="form-label">CVC</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="CVC"
                        />
                      </div>
                      <div className="col-12 mb-3">
                        {/* <label class="form-label">Card Number</label> */}
                        <input
                          type="email"
                          className="form-control"
                          placeholder="25$ monthly membership"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="btn send-mess">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Membership;
