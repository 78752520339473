import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./component/Home/Home";
import About from "./component/About/About";
import Benefit from "./component/Benefit/Benefit";
import Association from "./component/Association/Association";
import Login from "./component/auth/login";
import SignUp from "./component/auth/signup";
import Membership from "./component/auth/Membership";
import Header from "./component/layouts/Header";
import Footer from "./component/layouts/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/custom.css"
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from "./stripe";
function App() {
  const location = useLocation();
  const isAuthPage = location.pathname === "/login" || location.pathname === "/signup" || location.pathname === "/membership";

  return (
    <>
      {!isAuthPage && <Header />}
      <Routes>
   
        <Route path="/"  element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/benefit" element={<Benefit />} />
        <Route path="/association" element={<Association />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/membership" element={<Membership />} />
      </Routes>
      {!isAuthPage && <Footer />}
    </>
  );
}

function Main() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default Main;
