import React from "react";

const AboutDoc = ({ formShow }) => {
  return (
    <>
      <section className="AboutDoc" id="about">
        <div className="container">
          <div  className={formShow ? 'row' : 'row pt-0'}>
            <div className="row">
              <h2  className={formShow ? '' : 'backgroundH2'}>
                Personal Trainers, Nurses and Physicians In Collaboration for A
                Healthier World
              </h2>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card">
                <div className="img">
                  <img alt="img" src="./images/card1.png" />
                </div>
                <div class="card-content">
                  <h5>DIGITAL TOOLS</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card">
                <div className="img">
                  <img alt="img" src="./images/card2.jpg" />
                </div>
                <div class="card-content">
                  <h5>SEMINARS</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card">
                <div className="img">
                  <img alt="img" src="./images/card3.jpg" />
                </div>
                <div class="card-content">
                  <h5>TRAINING CENTER</h5>
                </div>
              </div>
            </div>


            <div className="col-lg6 col-md-6 col-sm-12"
             onClick={() => window.open('https://humanenergyhealthcare.com', '_blank')}
             style={{ cursor: 'pointer' }} >
              <div className="card align-items-center">
                <div className="" >
                  <img alt="img" style={{height:"150px"}} className="w-100" src="./images/LogoNew.png" />
                </div>
              </div>
            </div>
            <div className="col-lg6 col-md-6 col-sm-12"
             onClick={() => window.open('https://cancer-management.com', '_blank')}
             style={{ cursor: 'pointer' }} >
              <div className="card align-items-center">
                <div className="">
                  <img alt="img" style={{height:"150px"}} className="w-100" src="./images/cancer-silver.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutDoc;
