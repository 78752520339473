import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../environment.js/environment";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const validateForm = () => {
    let isValid = true;

    if (!email) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    if (!password) {
      setPasswordError(true);
      isValid = false;
    } else {
      setPasswordError(false);
    }

    return isValid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const requestBody = {
      email: email,
      password: password,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/Account/LoginMT`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      debugger
      if (response.status === 200) {
        toast.success("Login successful!");
        var body = await  response.json();
        window.location.href ='https://medicaltrainer.org/Doctors/Therapist_Dashboard?ft=0&secure=' + encodeURIComponent(body.token) + '&userId=' + body.user.userId;
      } else if (response.status === 401) {
        toast.error("Invalid login. Please check your credentials.");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="auth-login">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="form">
              <div className="auth-logo">
                <ToastContainer/>
                <Link to="/">
                  <img src="./images/logo.png"></img>
                </Link>
                <h4>Welcome back </h4>
                <p>Welcome back! Please enter your details.</p>
              </div>
              <div className="Custom-Form">
              <form >
                <div className="w-100">
                  <label className="form-label">Email*</label>
                  <input
                    type="email"
                    className={`form-control ${emailError ? "is-invalid" : ""}`}
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    
                  />
                  {emailError && (
                    <div className="invalid-feedback">Email is required</div>
                  )}
                </div>

                <div className="w-100">
                  <label className="form-label">Password*</label>
                  <input
                    type="password"
                    className={`form-control ${passwordError ? "is-invalid" : ""}`}
                    placeholder="**********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    
                  />
                  {passwordError && (
                    <div className="invalid-feedback">Password is required</div>
                  )}
                </div>

                <div className="d-flex flex-wrap flex-sm-wrap w-100 align-items-center justify-content-between">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label m-0" htmlFor="exampleCheck1">
                      Remember for 30 days
                    </label>
                  </div>
                  {/* <div>
                    <Link to="/forgot-password">Forgot password</Link>
                  </div> */}
                </div>
                <button type="button" onClick={handleLogin} className="btn send-mess" disabled={loading}>
                  {loading ? "Signing in..." : "Sign in"}
                </button>
                <p className="login-btn">
                  Don’t have an account? <Link to="/">Sign up</Link>
                </p>
              </form>
            </div>
              <p className="copy">© Copyright {new Date().getUTCFullYear()}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="content">
              <img src="./images/login.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
