import React from "react";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const Association = () => {
  return (
    <div className="wrapper">
      <section className="HowWorks">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="left-content">
                <h1>KEY FEATURES:</h1>
                <div className="content">
                  <p>
                    Everyone who registers (becomes registered in our system as
                    a Medical Trainer) must be sent an automatic welcome email
                    with Login info, which also includes an "address link" that
                    they can share on social media or forward by email to their
                    colleagues. This is an effective recruitment campaign to get
                    as many members as possible, ensuring good growth as quickly
                    as possible. Everyone who signs up must be given clear
                    information that they can get unlimited free months (years)
                    of their own membership by enlisting their colleagues.
                  </p>
                </div>
                <div className="content">
                  <h4>The rewards must be automated.</h4>
                  <p>
                    Whoever shares and spreads the message receives 1 month's
                    free membership (registered in our system as paid) for each
                    person who uses the link, or enters (automatically when
                    using the link) a reference code when they join the
                    association/ registers. That is, someone who has recruited
                    20 registered new members will have the next 20 months free
                    in our system. (But the system has then gained 20 more
                    paying members)
                  </p>
                  <p>
                    The system must be clear and easy to navigate. Below are
                    suggestions and rough drafts for setup. If we click on the
                    name of the member, we will bring up the personal info,
                    which is under Medical Trainer. Here, the number of months,
                    paid/unpaid , the number of clients/patients registered
                    under them with a 5USD monthly membership and the number of
                    12-month courses that the person has "sold" to their clients
                    must be shown .
                  </p>
                  <p>
                    The sharing model is that those who are registered under the
                    Global Doctors Association receive 50% commission. We do
                    this so that the system works easily for us who work in the
                    administration.
                  </p>
                </div>
                <div className="content">
                  <Tabs
                    defaultActiveKey="member"
                    id="uncontrolled-tab-example"
                    className="mb-3 pb-3"
                  >
                    <Tab eventKey="member" title="MT - Members">
                      <Table responsive striped bordered hover>
                        <thead>
                          <tr>
                            <th>Member no.</th>
                            <th>Name</th>
                            <th>Mail</th>
                            <th>Telephone</th>
                            <th>Country</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Stuart Hansen</td>
                            <td>stuart@gmail.com</td>
                            <td>+4765789003</td>
                            <td>Norway</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Spain</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Germany</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Sweden</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Norway</td>
                          </tr>

                          <tr>
                            <td>6</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Spain</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey="counntry" title="Country Managers">
                    <Table responsive striped bordered hover>
                        <thead>
                          <tr>
                            <th>Country</th>
                            <th>Country Manager</th>
                            <th>Mail</th>
                            <th>Telephone</th>
                            <th>Members</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Afghanistan</td>
                            <td>Ali Bhutanis</td>
                            <td>ab@care4u.com</td>
                            <td>+9386545678</td>
                            <td>450</td>
                          </tr>
                          <tr>
                            <td>Albania</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>210</td>
                          </tr>
                          <tr>
                            <td>Algeria</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>40</td>
                          </tr>
                          <tr>
                            <td>Andorra</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>20</td>
                          </tr>
                          <tr>
                            <td>Angola</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>58</td>
                          </tr>
                          <tr>
                            <td>Antigua and Barbuda</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>18</td>
                          </tr>

                          <tr>
                            <td>Argentina</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>18478</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Association;
