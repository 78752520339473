import React from "react";
import Hero from "./Hero";
import stripePromise from "./../../stripe";
import { Elements } from '@stripe/react-stripe-js';
const Home = () => {
  return (
    <div className="wrapper">
      <Elements stripe={stripePromise}>
        <Hero />
      </Elements>
      
    </div>
  );
};

export default Home;
