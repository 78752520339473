import React from "react";

const About = () => {
  return (
    <div className="wrapper">
      <section className="HowWorks">
        <div className="container">
          <div className="row">
            <div className="col-12 left-content">
              <h1>About us - Global Doctors Association</h1>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="left-content">
                <div className="content">
                  <p>
                    We work to build new structures that provide a higher
                    standard of care and excellent health for people working in
                    hospitals, clinics, and health studios.
                  </p>
                  <p>
                    The Global Doctors Association is a non-profit organization
                    where nurses, personal trainers, and physicians are welcome
                    as members
                  </p>
                  <p>
                    When you dedicate your time to working with health-promoting
                    services, it is a meaningful and important mission. We
                    support you with knowledge, education, digital tools, and
                    devices so you can do your job more efficiently, and with
                    less chance of burnout.
                  </p>
                  <p>
                    As a member of the Global Doctors Association, you will have
                    access to health- promoting programs that you can use,
                    either in one-to-one or group teaching of clients, or you
                    can give them a link so that they can use these digital
                    solutions.
                  </p>
                  <h4>FOCUS ON COMPREHENSIVE AND MODERN SOLUTIONS</h4>
                  <p>
                    Health is the most important thing we have in life. It
                    cannot be outsourced to others. The time has come to
                    establish well-functioning communication between nurses,
                    personal trainers, and physicians. This provides major
                    health benefits for your clients.
                  </p>
                </div>
                <div className="content">
                  <h4>SEMINARS FOR INCREASED COMPETENCE - MEDICAL TRAINER</h4>
                  <p>
                    The Global Doctors Association invites members to seminars
                    and courses with access to the best resources and the latest
                    technology and knowledge.
                  </p>
                  <p>
                    Medical Trainer is a 3-day certification course our newly
                    registered members are offered the opportunity to attend.
                  </p>
                  <p>
                    As a member, after 12 months, you will be invited to a
                    2-week Medical Master course. This will be held on Baha Mar
                    in Nassau Bahamas.
                  </p>
                  <p>
                    Our job is to facilitate and organize, so you can enjoy your
                    work, and assist your clients so they can live a life with
                    the best possible quality and health.
                  </p>
                  <p>
                    We create a collective awareness among healthcare workers
                    globally by sharing information. This safeguards patient
                    safety and contributes to the improvement of patient
                    treatments.
                  </p>
                </div>

                <div className="content">
                  <h4>ANTI-AGING & CANCER RESEARCH CENTER IN THE BAHAMAS!</h4>
                  <p>
                    One of the Global Doctors Association`s goals will be to
                    open a research center in Nassau Bahamas. The center shall
                    challenge areas that do not contribute to patients becoming
                    100% healthy. We focus on developing more humane and
                    efficient treatment methods for Cancer.
                  </p>
                </div>
                <div className="content">
                  <h4>PUTTING COMPLETE TREATMENT PROTOCOLS TOGETHER</h4>
                  <p>
                    We concentrate on solving one health challenge and diagnosis
                    at a time. When several methods are combined and new
                    technology is developed, we share these ground-breaking
                    results with members. The plan is to put this into protocols
                    where we establish a new and higher standard all members can
                    have available globally.
                  </p>
                </div>
                <div className="content">
                  <h4>RESEARCH CENTER FOR MEMBERS - LIFELONG LEARNING</h4>
                  <p>
                    This will be the main place for education where we teach
                    physicians, nurses, and personal trainers (members).
                  </p>
                </div>
                <div className="content">
                  <h4>DEVELOPING NEW TOOLS FOR HEALTH WORKERS</h4>
                  <p>
                    Global Doctors Association works under non-profit
                    principles, and everything developed as tools at our
                    research center will be made available for the lowest and
                    most reasonable prices (non-profit) possible for our
                    members.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 col-md-12">
              <div className="right-content">
                <div className="imglist">
                  <img
                    src="/images/work1.png"
                    style={{ marginBottom: "124px", marginTop: "123px" }}
                    className="w-100"
                  />
                  <img
                    src="/images/work3.png"
                    style={{ marginBottom: "120px" }}
                    className="w-100"
                  />
                  <img
                    src="/images/work4.png"
                    style={{ marginBottom: "71px" }}
                    className="w-100"
                  />
                  {/*  <img
                    src="/images/work5.png"
                    style={{ marginBottom: "50px" }}
                    className="w-100"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
