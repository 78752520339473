import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate, Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <Navbar fixed="top" expand="lg" className="custom-header destkop">
        <Container>
          <div className="header">
            <Navbar.Brand>
              <Link to="/">
                <img src="/images/logo.png" alt="logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link to="/about-us" className="nav-link">
                  About Us
                </Link>
                <Link to="/benefit" className="nav-link">
                  Benefits
                </Link>
                {/* <Link to="/association" className="nav-link">
                  Training Center
                </Link> */}
              </Nav>
              <div className="gap-md d-flex align-items-center ">
                <Link to="/login" className="btn btn-outline">
                  LOGIN
                </Link>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
