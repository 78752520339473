import React from "react";
import { Link } from "react-router-dom";
const SignUp = () => {
  return (
    <>
      <div className="auth-login">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="form">
              <div className="auth-logo">
                <Link to="/">
                  <img src="./images/logo.png"></img>
                </Link>
                <h4>Get access </h4>
                <p>Welcome back! Please enter your details.</p>
              </div>
              <div className="Custom-Form">
                <form>
                  <div className="w-100">
                    <label class="form-label">Name*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="w-100">
                    <label class="form-label">Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                    />
                  </div>

                  <button type="submit" class="btn send-mess">
                    Get access
                  </button>
                  <p className="login-btn">
                    Already have an account? <Link to="/login">Log in</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="content">
              <img src="./images/login.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
