import React from "react";

const Benefit = () => {
  return (
    <div className="wrapper">
      <section className="HowWorks">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="left-content">
                <h1>Membership Benefits - Global Doctors Association.</h1>
                <div className="content">
                  <p>
                    As a member of the Global Doctors Association, you will have
                    many benefits.
                  </p>
                </div>
                <div className="content">
                  <h4>Extra monthly income.</h4>
                  <p>
                    By implementing our digital tools and the knowledge we
                    develop as part of your toolbox, you can have multiple
                    sources of income.
                  </p>
                  <p>
                    We have a digital platform www.HumanEnergyHealthcare.com
                    (www.abc-getwell.com) where you can invite your clients to
                    "interact" with you. If they accept your invitation, they
                    pay only $5/month (You receive 50%). You will get full
                    training to use this platform at one of our seminars or
                    courses.
                  </p>
                  <p>
                    2000 clients registered gives you $5000 in monthly extra
                    income. If you stop your practice, you will still receive
                    this income when registered in our system.
                  </p>
                  <p>
                    Tool number 2 which you can use in your practice, as a
                    member, is an online 12-month cancer recovery program. You
                    can find this on the website www.Cancer-Management.com.
                    Clients pay only $2,500 for the entire program. You receive
                    $1250 for each registered participant.
                  </p>
                </div>

                <div className="content">
                  <h4>We invite you to seminars and courses.</h4>
                  <p>
                    The Global Doctors Association invites members to seminars
                    and courses with access to the best resources and the latest
                    science and technology.
                  </p>
                  <p>
                    Medical Trainer is a 3-day certification course our newly
                    registered members are offered the opportunity to attend.
                    This will teach you all the benefits of using our systems,
                    and why it is important to step up to the next level in
                    healthcare, for patient safety and well-being.
                  </p>
                  <p>
                    After 12 months as a member, you will be invited to a 2-week
                    (14 days) Medical Master course. This will be held on Baha
                    Mar www.BahaMar.com in Nassau Bahamas.
                  </p>
                  <p>
                    Members with more than 300 registered clients on
                    www.HumanEnergyHealthcare.com get this 2-week course for
                    free. This 2-week course costs $7,600, including the stay at
                    the Grand Hyatt, and activities like golf, diving, and a
                    boat trip.
                  </p>
                </div>
                <div className="content">
                  <h4>Anti-aging & cancer research center in the Bahamas.</h4>
                  <p>
                    One of the Global Doctors Association's goals will be to
                    open a research center in Nassau Bahamas.
                  </p>
                </div>
                <div className="row pt-0 pb-5">
                  <div className="col-6">
                    <img src="./images/1.png" style={{height:"500px", borderRadius:"20px"}} className="w-100" />
                  </div>
                  <div className="col-6">
                    <img src="./images/2.png" style={{height:"500px", borderRadius:"20px"}} className="w-100" />
                  </div>
                </div>
                <div className="content">
                  <h4>Complete treatment protocols for all members to use.</h4>
                  <p>
                    We concentrate on solving one health challenge and diagnosis
                    at a time. When several methods are combined and new
                    technology is developed, we share these ground-breaking
                    results with members. The plan is to put this into protocols
                    as a higher standard all members can have globally.
                  </p>
                  <p>
                    We focus on developing more humane and efficient treatment
                    methods for Cancer.
                  </p>
                </div>
                <div className="content">
                  <h4>Research center for members - lifelong learning.</h4>
                  <p>
                    This will be the main place for education where we teach
                    physicians, nurses, and personal trainers (members).
                  </p>
                  <p>
                    Today, there are hundreds of treatment methods, and at least
                    as many products and machines that claim to be able to cure
                    cancer. Often it can be researchers and professors who have
                    found that something works, and they start promoting their
                    "findings" as a solution that fits all.
                  </p>
                  <p>
                    In today's digital society, there have been extremely many
                    people who publish claims of healing using food, drinks,
                    herbs, and products they sell. This can create confusion
                    and, in some cases, lead to people who need help
                    unfortunately not being able to access the right tools or
                    treatments as there is an overload of information.
                  </p>
                  <p>
                    We already have a good deal of experience with various
                    methods and have tested a lot of treatment methods, devices,
                    and modern technology. Some we can write off as not good
                    enough, and some methods are very effective and can be
                    recommended.
                  </p>
                  <p>
                    Part of our responsibility is to receive submitted
                    information from members who want to test out treatment
                    methods, new technology, and products. We filter out methods
                    that do not work effectively enough and are left with those
                    that work to promote health every time they are used.
                  </p>
                </div>
                <div className="content">
                  <h4>
                    Based on our research we are developing new tools for health
                    workers.
                  </h4>
                  <p>
                    Global Doctors Association works under non-profit
                    principles, and everything developed as tools at our
                    research center will be made available for reasonable prices
                    (non-profit) possible for our members.
                  </p>
                  <p>
                    We create a collective awareness among healthcare workers
                    globally by sharing information. We aim to secure the best
                    possible practice in the future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Benefit;
