import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {



  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <ul className="list-inline">
                
                <li>
                  <Link to="/benefit">Benefits</Link>
                </li>
                <li>
                  {/* <Link to="/association">Training Center</Link> */}
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <img src="/images/logo.png" className="w-100" alt="logo" />
            </div>
            <div className="col-lg-5">
              <ul className="list-inline">
               
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/membership">Membership</Link>
                </li>
               
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
